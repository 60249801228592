import React from "react";
import { useImageBuilder } from "../../utils/image";
import { useLinkBuilder } from "../../utils/link";
import { CardItemType } from "./blocks";
import { stripHtml } from "../../utils/static";

type CardGroupProps = {
  title?: string;
  grid?: number;
  items?: Array<CardItemType>;
}

export default function CardGroup({title, grid, items}: CardGroupProps) {
  const {buildImageUrl} = useImageBuilder();
  const {buildLinkByPage} = useLinkBuilder();

  if ((items?.length ?? 0) === 0) {
    return null;
  }

  const renderCard = (item: CardItemType, index: number) => {
    if (!item || !item.image || !item.text) {
      return null;
    }

    return (
      <div key={`card-${index}`} className="col">
        <div className="card h-100">
          <a className="card_group_link" href={item.link ? buildLinkByPage(item?.link) : undefined} rel="internal" tabIndex={0}>
            <img src={buildImageUrl(item.image, 520, 220)} className="card-img-top" alt={stripHtml(item.text)} />
            <div className="card-body">
              <div className="col-xs-12 col-md-4 left">
                <img src={buildImageUrl(item.logo, 202, 128)} alt={stripHtml(item.text)} />
              </div>
              <div className="col-xs-12 col-md-8 right">
                <p className="card-text" dangerouslySetInnerHTML={{__html: item?.text ?? ''}}></p>
              </div>
            </div>
          </a>
        </div>
      </div>
    )
  };

  return (
    <section className="mamcom_component mam_partner_card_group_container">
      <div className="row">
        <div className="col-sm-12">
          <header className="container_header mb-4 col-sm-10 offset-sm-1">
            <div className="heading">
              <h3 dangerouslySetInnerHTML={{__html: title ?? ''}} />
            </div>
          </header>
          <div className={`row row-cols-2 row-cols-lg-${grid} g-4 pb-2`}>
            {items?.map(renderCard)}
          </div>
        </div>
      </div>
    </section>
  )
}
