import React, { useEffect, useMemo } from "react";
import { SnackbarProvider } from "notistack";

// project import
import ApiContextProvider from "./api";
import ScrollTop from "./components/ScrollTop";
import TranslationContextProvider from "./context/translation";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //
import "./assets/css/styles.css";
import "bootstrap/js/index.umd";
import { useParams } from "react-router";

const App = () => {
  const navigate = useNavigate();
  const {search, pathname} = useLocation();
  const {locale} = useParams();

  const params = new URLSearchParams(search);
  let country = params.get("site");
  let lang = params.get('language') ?? params.get('l');
  let i18n = `${lang?.toLowerCase()}-${country?.toUpperCase()}`;

  if (!country || !lang) {
    const localLang = localStorage.getItem('mam:lang');
    if (localLang) {
      i18n = localLang;
    } else {
      i18n = 'de-DE';
    }
  }

  localStorage.setItem('mam:lang', i18n);

  useEffect(() => {
    if (window.top && document.location === window.top.location) {
      if (!params.has("noredirect")) {
        let pathItems = pathname.split('/').filter(pathItem => pathItem.length > 0);

        // parse old deep-links to new schema
        let parsedOld = null;
        if (pathname.includes('T117120') || pathname.includes('T100384')) {
          const uniqueName = pathItems[pathItems.length - 1];
          parsedOld = `/partner/${uniqueName}${uniqueName.includes('_de') ? `` : `_de`}`;
          pathItems = parsedOld.split('/');
        }

        const pathString = pathItems.join('--');
        const query = new URLSearchParams();
        let catIndex = 1;
        for (const cat of pathString?.match(/.{1,30}/g) ?? []) {
          query.set(`cat${catIndex}`, cat);
          catIndex++;
        }

        let el = document.createElement("div");
        el.style.position = "absolute";
        el.style.top = "0";
        el.style.left = "0";
        el.style.height = "100%";
        el.style.width = "100%";
        el.style.backgroundColor = "white";
        el.style.zIndex = "9999";
        document.body.appendChild(el);

        let append = '';
        if (query.size > 0) {
          append = `?${query.toString()}`;
        }
        if (window.location.host.includes('localhost')) {
          window.location.href = `/iframe.html${append}`;
          return;
        }
        window.location.href = (("https:" === document.location.protocol) ? "https" : "http") + `://www.miles-and-more.com/${country ?? 'de'}/${lang ?? 'de'}/earn/shopping/shopping-platform.html${append}`;
      }
    } else {
      let redirectString = "";
      for (const index of Array.from(Array(4).keys())) {
        const cat = params.get(`cat${index}`);
        if (cat) {
          redirectString += cat;
        }
      }
      while (redirectString.includes('--')) {
        redirectString = redirectString.replace('--', '/');
      }
      const cat1 = params.get('cat1');
      if (cat1) {
        if (cat1.includes('partner')) {
          navigate(redirectString);
        } else {
          window.location.href = redirectString;
        }
      }
    }
  }, []);

  // rerender on networkError
  return useMemo(
    () => {
      return (
        <ApiContextProvider i18n={locale ?? i18n}>
          <TranslationContextProvider i18n={locale ?? i18n}>
            <SnackbarProvider maxSnack={3}>
              <ScrollTop>
                <Outlet />
              </ScrollTop>
            </SnackbarProvider>
          </TranslationContextProvider>
        </ApiContextProvider>
      );
    },
    []
  );
};

export default App;
