import React from "react";
import SlickSlider from "../wrapper/SlickSlider";
import { useImageBuilder } from "../../utils/image";
import { useLinkBuilder } from "../../utils/link";


type HomeSliderProps = {
  items: Array<any>;
}

export default function HomeSlider({items = []}: HomeSliderProps) {
  const {buildImageUrl} = useImageBuilder();
  const {buildLinkByPage} = useLinkBuilder();

  const filteredItems = items.filter(item => item);

  return (
    <React.Fragment>
      <section className="mamcom_component mam_carousel_container overflow-visible">
        <div className="row">
          <div className="col-sm-12 slider-container">
            {filteredItems.length > 0 && (
              <SlickSlider
                id="mam-slider"
                items={filteredItems}
                settings={{
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  rows: 1,
                  autoplay: true,
                  autoplaySpeed: 9500,
                  dots: true,
                  arrows: filteredItems.length > 1,
                  infinite: filteredItems.length > 1,
                  variableWidth: true,
                  adaptiveHeight: false,
                }}
              />
            )}
          </div>
        </div>
      </section>
      <section className="mamcom_component mam_partner_card_list_container">
        <div className="row">
          <div className="col-sm-10 offset-sm-1 row-cols-2 row-cols-sm-3 row-cols-md-5">
            {filteredItems?.slice(0, 10).map((item, index) => {
              if (!item) return null;
              return (
                <div key={`slider-card-${index}`} className="partner_card col">
                  <a className="partner_card_link" href={item.link ? buildLinkByPage(item.link) : undefined} rel="internal" tabIndex={0}>
                    <div className="partner_card_logo_alignment">
                      <div className="image_container">
                        <img src={buildImageUrl(item.logo, 202, 128)} alt={item.logo?.file_id} />
                      </div>
                    </div>
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </React.Fragment>
  )
    ;
}
